import React from 'react';
import CustomBreadcrumb from '../../components/CustomBreadcrumb';
import LinkWithQuery from '../../components/LinkWithQuery';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import HrefLangManager from '../../components/HrefLangManager';
import PassionSlider from '../../components/horizon/PassionSlider';
import LandingPageCta from '../../components/LandingPageCta';
import VideoIframe from '../../components/VideoIframe';

const Horizon = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	return (
		<Layout>
			<Seo
				title="Horizon IX hearing aids by hear.com | Best Hearing Aids | Bluetooth & Rechargeable | No-Risk Trial"
				description="The Horizon IX hearing aids are revolutionizing hearing care with smaller, smarter technology with unparalleled speech clarity, Bluetooth, and rechargeability."
			/>
			<HrefLangManager
				alternates={[
					['x-default', 'https://www.hear.com/hearing-aids/horizon/'],
					['en', 'https://www.hear.com/hearing-aids/horizon/'],
					['en-US', 'https://www.hear.com/hearing-aids/horizon/'],
					['en-IN', 'https://www.hear.com/in/horizon/'],
					['en-ZA', 'https://www.hear.com/za/horizon/'],
					['en-MY', 'https://www.hear.com/my/horizon/'],
					['ko-KR', 'https://www.hear.com/kr/horizon']
				]}
			/>
			<div className="u-overflow-hidden">
				<section className="o-row-4p5">
					<div className="c-horizon-hero">
						<video
							className="c-horizon-hero__video"
							data-object-fit="cover"
							src="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/video/horizon-hero.mp4"
							poster="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/video/horizon-hero.jpg"
							autoPlay
							loop
							playsInline
							muted
						></video>
						<div className="c-horizon-hero__copy">
							<div className="o-wrapper">
								<p className="c-horizon-hero__title">
									<span className="c-horizon-hero__sup-title">GET STARTED WITH HEAR.COM</span>
									Where Forever Young Begins
								</p>
								<LandingPageCta copy="TRY TODAY" classes="c-cta c-cta--brand" />
							</div>
						</div>
						<div className="horizon-hero__arrow u-mobile-hidden">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/horizon/caret-circle-down.svg"
								alt=""
								loading="lazy"
							/>
						</div>
					</div>
				</section>

				<section className="o-section">
					<div className="o-wrapper">
						<div className="o-row-2p5">
							<CustomBreadcrumb location={location} crumbs={crumbs} />
						</div>
						<div className="o-row-2p5">
							<div className="c-horizon-side-by-side c-horizon-side-by-side--mobile-flipped">
								<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--dual">
									<img
										className="o-fluid-img"
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
										alt="Horizon Go IX hearing aids in black box"
										loading="lazy"
									/>
								</div>
								<div className="c-horizon-side-by-side__copy">
									<div className="o-row-1p5">
										<h1 className="c-horizon-body-title">
											We've dared to <span className="u-color-brand">change</span> hearing care with the new Horizon IX device
										</h1>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											Never before in this industry have the insights from experts, engineers, and millions of hearing
											consultations been used to address the unique needs of younger, more active people with hearing
											loss.
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											Our fiery passion has driven us to take hearing care to the next level with performance so unique,
											it will open up a world of adventure and wellbeing you never thought existed. Get ready to open
											your mind, and your ears, to a hearing experience beyond your wildest imagination.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="o-row-2p5">
							<div className="c-horizon-side-by-side">
								<div className="c-horizon-side-by-side__copy">
									<div className="o-row-1p5">
										<h2 className="c-horizon-body-title">
											<span className="u-color-brand">RealTime</span> Conversation Enhancement
										</h2>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											We present the groundbreaking IX-Technology (Integrated Experience), the ideal hearing aid for
											conversations with multiple participants. This hearing aid technology analyses data about your
											conversation situation, including the number of participants, language, their direction and
											proximity.
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											The voices of the people participating in the conversation are highlighted from the background and
											it dynamically adjusts the speech contrast to the listening environment ensuring clarity in group
											conversations.
										</p>
									</div>
								</div>
								<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--dual">
									<img
										className="o-fluid-img"
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-ix-technology-in-conversations.gif"
										alt="IX technology in conversations"
										loading="lazy"
									/>
								</div>
							</div>
						</div>
						<div className="o-row-2p5">
							<div className="c-horizon-side-by-side c-horizon-side-by-side--mobile-flipped">
								<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--dual">
									<img
										className="o-fluid-img"
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/dual-processor-technology.jpg"
										alt="Horizon Go IX hearing aids dual processor technology"
										loading="lazy"
									/>
								</div>
								<div className="c-horizon-side-by-side__copy">
									<div className="o-row-1p5">
										<h2 className="c-horizon-body-title">
											<span className="u-color-brand">Dual</span> Processor Technology
										</h2>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											The strength of hear.com's new Horizon IX hearing aid comes to the fore in complex hearing situations with
											several sound sources and speakers. Using two separate processors, this state-of-the-art
											technology significantly increases the contrast between speech and background noise. The result:{' '}
											<LinkWithQuery to="/hearing-aids/" className="c-md-a">
												Hearing aids
											</LinkWithQuery>{' '}
											have never sounded so natural, detailed <span className="u-inline-block">and clear.</span>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="o-row-0">
							<div className="c-horizon-side-by-side">
								<div className="c-horizon-side-by-side__copy-pt">
									<div className="o-row-1p5">
										<h2 className="c-horizon-body-title">
											The first <span className="u-color-brand">bluetooth</span> hearing aid for younger, active people.
										</h2>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											We've combined sleek, sexy and nearly invisible design with cutting-edge features and unparalleled
											power to create a listening experience that's second to none. More than just a hearing aid, the Horizon IX
											ensures all of life's experiences are within your reach.
										</p>
									</div>
								</div>
								<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--50-w c-horizon-side-by-side__img--align-bottom">
									<img
										className="o-fluid-img"
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/horizon/horizon-running.jpg"
										alt="Woman running with Horizon hearing aids"
										loading="lazy"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="u-bg-grey">
					<div className="o-row-2">
						<div className="u-text-center o-wrapper">
							<h2 className="c-horizon-body-title c-horizon-body-title--pt">
								Pursue your <span className="u-color-brand">passions</span>
							</h2>
						</div>
					</div>
					<div className="o-row-11">
						<div className="u-text-center o-wrapper">
							<p className="c-horizon-body-copy c-horizon-body-copy--alt">
								Meeting your most active needs and delivering awesome performance in any situation. Now you can embrace
								the freedom to live your best life.
							</p>
						</div>
					</div>
					<div className="o-row-11">
						<PassionSlider />
					</div>
					<div className="o-row-2">
						<div className="u-text-center o-wrapper o-wrapper--fixed">
							<h2 className="c-horizon-body-title">
								Activate your <span className="u-color-brand">superpowers</span>
							</h2>
						</div>
					</div>
					<div className="o-row-6p5">
						<div className="u-text-center o-wrapper o-wrapper--fixed">
							<p className="c-horizon-body-copy c-horizon-body-copy--alt">
								Enjoy every moment to the fullest with hear.com’s new and improved Horizon IX hearing aids. Not only will you
								hear better, but your youthful edge will help you outperform everyone around you.
							</p>
						</div>
					</div>
					<div className="o-wrapper o-wrapper--fixed">
						<div className="c-horizon-side-by-side c-horizon-side-by-side--mobile-flipped">
							<div className="c-horizon-side-by-side__copy-pt c-horizon-side-by-side__copy-pr u-padding-b-0-mobile">
								<div className="o-row">
									<p className="c-horizon-body-copy">
										<strong>
											Great conversations start with a <span className="u-color-brand">boost...</span>
										</strong>
									</p>
								</div>
								<div className="o-row u-padding-b-1">
									<p className="c-horizon-body-copy">
										Thanks to an unsurpassed Speech Focus with incredibly narrow sound directionality, you’ll be
										laser-focused on what’s important to you — even in difficult listening environments such as a busy
										restaurant or conference room. Just tap the app to rise above the crowd — and the noise — and you'll
										never miss a single detail again.
									</p>
								</div>
							</div>
							<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--50-w c-horizon-side-by-side__mobile-top-row o-flush-mobile">
								<video
									className="o-fluid-img c-horizon-side-by-side__video u-relative"
									src="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/horizon_soundwaves_animation.mp4"
									poster="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/horizon_soundwaves_animation.jpg"
									autoPlay
									loop
									playsInline
									muted
								></video>
							</div>
						</div>
					</div>
				</section>

				<section className="o-row-6p5">
					<div className="o-wrapper">
						<div className="c-horizon-side-by-side c-horizon-side-by-side--alt">
							<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--50-w c-horizon-side-by-side__mobile-top-row o-flush-mobile">
								<video
									className="o-fluid-img c-horizon-side-by-side__video"
									src="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/Zenmode_horizon_final.mp4"
									poster="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/Zenmode_horizon_final.jpg"
									autoPlay
									loop
									playsInline
									muted
								></video>
							</div>
							<div className="c-horizon-side-by-side__copy-pt--3 c-horizon-side-by-side__copy-pl">
								<div className="o-row">
									<p className="c-horizon-body-copy">
										<strong>
											…and end with ultimate <span className="u-color-brand">relaxation.</span>
										</strong>
									</p>
								</div>
								<div className="o-row-2">
									<p className="c-horizon-body-copy">
										Because sometimes you just need a break. Your Horizon IX devices will help you escape the chaos of the day
										with its ultra-zen Relax Mode that helps you take it down a notch and let it all go with the sounds
										of the sea. So go on… put your feet up and enjoy the fading ambient sounds to reduce stress and
										increase mindfulness. You deserve this.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="o-row-6p5 u-bg-grey">
					<div className="u-text-center o-wrapper">
						<div className="o-row-4p5">
							<h2 className="c-horizon-body-title c-horizon-body-title--pt">
								Smaller, <span className="u-color-brand">smarter</span> hearing aids
							</h2>
						</div>
						<div className="o-row-2">
							<div className="c-horizon-extras-cards-row">
								<div className="c-horizon-extras-card">
									<div className="o-row-2">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/horizon/visibility.svg"
											className="c-horizon-extras-card__icon"
											alt=""
											loading="lazy"
										/>
									</div>
									<div className="o-row-1">
										<p className="c-horizon-extras-card__copy">
											<strong>Nearly Invisible</strong>
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-extras-card__copy">
											So tiny, you’ll even forget you're wearing it. Award-winning designs rival the look of the most
											popular headphones and ear pods today, so don’t be surprised if you find yourself wanting people
											to notice them.
										</p>
									</div>
								</div>
								<div className="c-horizon-extras-card">
									<div className="o-row-2">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/horizon/connection.svg"
											className="c-horizon-extras-card__icon"
											alt=""
											loading="lazy"
										/>
									</div>
									<div className="o-row-1">
										<p className="c-horizon-extras-card__copy">
											<strong>Connected to all devices</strong>
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-extras-card__copy">
											Easily adjust the volume or settings with a tap on your smartphone and never miss a call again.
											Stream TV shows, podcasts, audiobooks, and phone calls directly with no additional devices needed.
										</p>
									</div>
								</div>
								<div className="c-horizon-extras-card">
									<div className="o-row-2">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/horizon/diamond.svg"
											className="c-horizon-extras-card__icon"
											alt=""
											loading="lazy"
										/>
									</div>
									<div className="o-row-1">
										<p className="c-horizon-extras-card__copy">
											<strong>Unrivaled Speech Clarity</strong>
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-extras-card__copy">
											hear.com's unique algorithm is clinically proven to deliver an insane level of speech clarity in
											any environment like meetings, restaurants or parties. So you can keep the conversation flowing,
											instead of asking for a replay.
										</p>
									</div>
								</div>
								<div className="c-horizon-extras-card">
									<div className="o-row-2">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/horizon/charge.svg"
											className="c-horizon-extras-card__icon"
											alt=""
											loading="lazy"
										/>
									</div>
									<div className="o-row-1">
										<p className="c-horizon-extras-card__copy">
											<strong>Rechargeable</strong>
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-extras-card__copy">
											Never fumble around with tiny batteries again. And most of all, your schedule is no longer
											dictated by your hearing aids. Portable charging cases free you up so you can stay on the go.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="o-row-7p5">
					<div className="o-wrapper">
						<div className="o-row-4">
							<h2 className="c-horizon-body-title u-text-center">The Secret of hear.com's <span className="u-color-brand">Game-changing</span> hearing aids</h2>
						</div>
						<div className="o-row-3">
							<VideoIframe
								title="Game-changing hearing aids by hear.com"
								width="560"
								height="315"
								src="https://www.youtube.com/embed/5X6PxLkCqZY?si=oRXb4A6eHKwUyePQ&cc_load_policy=1"
							/>
						</div>
						<div className="o-row-4">
							<h2 className="c-horizon-body-title u-text-center">
								Choose your <span className="u-color-brand">perfect</span> fit
							</h2>
						</div>
						<div className="o-row-5">
							<p className="c-horizon-body-copy u-text-center">
								Wearing a hearing aid should reflect your personal style. Whether you’re looking for a device that’s
								more discreet, or prefer a bit of flare, there is a hear.com hearing aid that was made just for you.
							</p>
						</div>
						<div className="o-row-6p5">
							<div className="c-horizon-side-by-side c-horizon-side-by-side--desk-flipped">
								<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--go">
									<img
										className="o-fluid-img"
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/trying-go-ix-black.jpg"
										alt="Trying on Horizon Go IX hearing aids by hear.com"
										loading="lazy"
									/>
								</div>
								<div className="c-horizon-side-by-side__copy-pt-m">
									<div className="o-row-1p5">
										<p className="c-horizon-body-copy">
											<strong>Horizon Go IX</strong>
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											With rechargeable battery power that can go all day, the{' '}
											<LinkWithQuery to="/hearing-aids/horizon/go-ix/" className="c-md-a">
												Horizon Go IX
											</LinkWithQuery>{' '}
											is one of the most popular hearing aids for staying connected while on the go. Featuring the
											innovative IX platform technology, this device easily conquers the most complex hearing
											situations.
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											It uses two separate sound processors to separate speech and background noise. This means you’ll
											better understand speech while the environment around you sounds more natural — and background
											noise stays in the background.
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											Suitable for mild to profound hearing loss, the Horizon Go IX features include rechargeability, Bluetooth
											connectivity, Panorama Mode for a 360-degree sound experience, Speech Focus, Relax Mode and more.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="o-row-6p5">
							<div className="c-horizon-side-by-side">
								<div className="c-horizon-side-by-side__img c-horizon-side-by-side__img--mini">
									<img
										className="o-fluid-img"
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/trying-mini-ix.jpg"
										alt="Trying on Horizon Mini IX hearing aids by hear.com"
										loading="lazy"
									/>
								</div>
								<div className="c-horizon-side-by-side__copy-pt-m">
									<div className="o-row-1p5">
										<p className="c-horizon-body-copy">
											<strong>Horizon Mini IX</strong>
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											Recognized as one of the best and smallest hearing aids in the world for those with mild hearing
											loss, the{' '}
											<LinkWithQuery to="/hearing-aids/horizon/mini-ix/" className="c-md-a">
												Horizon Mini IX
											</LinkWithQuery>{' '}
											disappears completely inside your ear. But don’t let the small package fool you. The Mini delivers
											a powerful punch with its X technology platform, providing you with the most crystal-clear sound
											around.
										</p>
									</div>
									<div className="o-row-2">
										<p className="c-horizon-body-copy">
											You’ll enjoy smartphone connectivity for discreet adjustments, Speech Focus, Relax Mode, acoustic
											motion sensors that adjust to your environment, and a virtually invisible fit.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="u-text-center">
						<LandingPageCta copy="TRY TODAY" classes="c-cta c-cta--brand" />
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default Horizon;
