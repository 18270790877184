import React from 'react';
import Slider from 'react-slick';

function PassionSlider() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 1,
		centerMode: true,
		arrows: true,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	return (
		<div className="c-passions-slider">
			<Slider {...settings}>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-1.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Mile 12 at Dakota Ridge</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-2.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">On site in The Valley</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-3.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Discovering Audrey is pregnant</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-4.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Camping in Yellowstone</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-5.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Surprise dinner for mom</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-6.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Bird hunting in Virginia</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-7.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">
						Shopping at the
						<br /> farmers market
					</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-8.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Fishing in Lake Erie</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-9.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">Exploring the history museum</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-10.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">The 18th hole at Pebble Beach</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
				<div className="c-passions-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/activity-11.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<p className="c-passions-slider-slide__copy">
						Getting down at
						<br /> The Boogie Room
					</p>
					<div className="c-passions-slider-slide__gradient"></div>
				</div>
			</Slider>
		</div>
	);
}

export default PassionSlider;
